<template>
    <div  v-if="packages.length"  class="container-wrapper">
      <div v-for="item in packages" :key="item.id" class="package">
        <package-component :item="item"/>
      </div>
    </div>
</template>

<script>
import PackageComponent from "./PackageComponent";
import {mapActions, mapState} from "vuex";

export default {
  name: "PackagesComponent",
  components: {PackageComponent},
  props: {
    limit: {
      default: null,
      type: Number,
      required: true,
    }
  },
  methods: {
    ...mapActions(['GET_PACKAGES_CACHED']),
  },
  computed: {
    ...mapState({
      packages: state => state.payment.packagesList,
    }),
  },
  mounted() {
    this.GET_PACKAGES_CACHED({limit: this.limit})
  },
}
</script>

<style lang="scss" scoped>
  .container-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(17rem, 1fr));
    gap: .5rem;
  }

  .package {
    width: 100%;
    display: flex;
  }
</style>